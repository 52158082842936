import React from "react";
import { Reveal } from "../../../Hooks/Reveal";
import { Link } from "react-router-dom";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";
import { useSpring, animated, config, easings } from "react-spring";

function Values() {
  return (
    <>
      <div className="bg-dark relative z-8">
        <section className="container relative mx-auto px-4 z-10 md:py-12 lg:py-12 py-12  text-center lg:text-left">
          <div className="px-0 py-8 md:px-2 basis-1/1">
            <ScrollEffect YFrom={-80} YTo={-20}>
              <h3 className="text-3xl lg:text-5xl mb-3 text-center uppercase italic leading-tight font-heading">
                This is for<span className="text-primary"> the Elite</span>
              </h3>
            </ScrollEffect>
          </div>
          <ScrollEffect YFrom={5} speed={-10}>
            <div className="flex flex-col lg:flex-row">
              <div className="px-0 py-4 md:px-2 md:py-12 md:basis-1/2 basis-1/1">
                <p class="mb-8 relative z-10">
                  At EES Fitness, we understand that every athlete wants to
                  perform at their best. That's why we offer customized training
                  programs that can help you achieve an elite level of health
                  and fitness in your field. Whether you're a professional
                  athlete or simply looking to improve your performance in your
                  sport or activity of choice, Elizabeth can help you reach your
                  goals.
                </p>
                <p class="mb-8 relative z-10">
                  Our training programs focus on four key areas: agility,
                  strength & conditioning, speed, and stamina. Agility training
                  helps you develop the ability to move quickly and change
                  direction with precision, while strength & conditioning is
                  crucial for building the physical foundation needed for
                  success in any sport or activity. Speed training helps you
                  increase your overall speed, while stamina training helps you
                  build endurance and stay at the top of your game for longer
                  periods of time.
                </p>
              </div>

              <div className="px-0 py-4 md:px-2 md:py-12 md:basis-1/2 basis-1/1">
                <p class="mb-8 relative z-10">
                  At Elizabeth's website, we believe that a comprehensive
                  approach to training is essential for achieving peak
                  performance. That's why our programs incorporate a variety of
                  exercises and techniques designed to target each of these key
                  areas, including weight training, cardio, plyometrics, and
                  more. We also take into account your unique goals, fitness
                  level, and sport-specific needs when creating your program, so
                  you can be sure that you're getting the best possible training
                  for your needs.
                </p>

                <p class="mb-8 relative z-10">
                  Whether you're a competitive athlete looking to gain an edge,
                  or simply want to take your fitness to the next level,
                  Elizabeth can help you achieve your goals. Contact us today to
                  learn more about our elite training programs and how we can
                  help you achieve peak performance in your sport or activity of
                  choice.
                </p>
              </div>
            </div>
          </ScrollEffect>
        </section>
      </div>
    </>
  );
}

export default Values;
