import React from "react";
import { default as borderIMG } from "../../images/assets/border.svg";
import { default as cross } from "../../images/assets/cross.svg";
import { default as squiggle } from "../../images/assets/sqiggle.svg";
import jumpingGirl from "../../images/assets/jumping-girl.png";
import { Link } from "react-router-dom";
import { useParallax } from "react-scroll-parallax";
import TextLoop from "react-text-loop";
import "../../css/assets.css";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";
//import { ParallaxProvider } from 'react-scroll-parallax';

function TopBanner() {
  const jumpingImage = {
    backgroundImage: `url(${jumpingGirl})`,
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "100%",
    position: "absolute",
    backgroundSize: "contain",
    backgroundPosition: "center",
  };

  const corossImage = {
    right: "-20px",
    top: "-10px",
    width: "20%",
    maxWidth: "80px",
    position: "absolute",
  };

  const squiggleImage = {
    right: "-50px",
    bottom: "10px",
    left: "0px",
    position: "absolute",
  };

  const slowScroll = useParallax({
    speed: -5,
    translateX: [15, -40],
  });

  // const rotate = useParallax({
  //   rotate: [-40, 20],
  //   speed: -5,
  // });

  const jumpinMovement = useParallax({
    rotate: [5, -5],
    speed: -5,
  });

  const textSlow = useParallax({
    speed: -15,
  });

  return (
    <div className="relative bg-dark pt-24 pb-24 md:pb-8">
      <div className="opacity-0 md:opacity-100 absolute right-0 bottom-0 md:w-1/3 lg:w-6/12 w-6/12 h-full">
        <img
          className="h-full w-full absolute bottom-0 right-0"
          src={borderIMG}
        ></img>
      </div>
      <div className="opacity-20 lg:pl-0 md:pl-80 sm:pl-0 md:opacity-100 ml-90 absolute md:right-0 bottom-0 md:w-full lg:w-6/12 h-full w-full pt-24 lg:pt-24">
        <div
          className="z-50"
          ref={jumpinMovement.ref}
          style={jumpingImage}
        ></div>
      </div>

      <div className="relative container px-4 flex flex-wrap md:flex-row-reverse">
        <div className="relative top-0 right-0 w-1/1 md:w-6/12 lg:w-6/12 lg:pt-24"></div>
        <div className="w-full left-0 md:w-6/12 lg:w-6/12 mr-auto py-24 md:py-40 text-center md:text-left relative">
          <div style={corossImage} className="absolute z-10 md:block">
            <AnimatedAsset
              duration={53000}
              rotate={15}
              minusrotate={-300}
              posx={-16}
              minusx={22}
              posy={30}
              minusy={-40}
            >
              <img className="" alt="Cartoon Coin mascot" src={cross} />
            </AnimatedAsset>
          </div>
          <div className="relative">
            <div ref={textSlow.ref}>
              <h1 className="italic uppercase text-white text-3xl md:text-4xl lg:text-5xl xl:text-5xl leading-tight font-heading">
                <span>Join to improve</span>
                <br />
                <TextLoop
                  springConfig={{ stiffness: 80, damping: 12, delay: 10 }}
                >
                  <span className="italic text-primary uppercase">
                    Conditioning
                  </span>
                  <span className="italic text-primary uppercase">Speed</span>
                  <span className="italic text-primary uppercase">
                    Flexability
                  </span>
                  <span className="italic text-primary uppercase">Agility</span>
                </TextLoop>
              </h1>

              <p className="text-white">Get Started With EES Fitness</p>
              <div className=" pt-2 pb-10">
                <Link to="/Contact" className="">
                  <button class="font-bold bg-gradient-to-r from-blue to-secondary text-dark px-8 mt-5 group inline-block hover:from-primary hover:to-blue py-2 text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
                    Join Now!
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="inline-block h-8 w-10 ml-2 group-hover:translate-x-5 transition transition-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </svg>
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div style={squiggleImage} className="absolute z-10 md:block">
            <AnimatedAsset
              duration={5000}
              rotate={0}
              minusrotate={0}
              posx={-16}
              minusx={22}
              posy={5}
              minusy={-5}
            >
              <img
                ref={slowScroll.ref}
                className="absolute squiggleImage"
                src={squiggle}
              ></img>
            </AnimatedAsset>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBanner;
