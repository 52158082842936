import React from "react";
import HomeAboutBlock from "./Home/HomeAboutBlock";
import SpeedAgility from "./Home/SpeedAgility";
import Flexibility from "./Home/Flexibility";
import ServicesHome from "./Home/ServicesHome";
import TopBanner from "./Home/TopBanner";
import FAQDropdown from "../../Hooks/FAQDropdown";
//Flexibility
//import ParallaxCache from "./ParallaxCache";

// import BottomBanner from "../BottomBanner"
//import { useParams } from 'react-router-dom';
//import HelloWorld from '../HelloWorld'

export default function Home() {
  const svgStyleTop = {
    position: "absolute",
    color: "white",
    width: "100%",
    top: "-1px",
  };

  const svgStyleBottom = {
    position: "absolute",
    color: "white",
    width: "100%",
    bottom: "-1px",
  };

  return (
    <div>
      <TopBanner className="relative z-40" />

      <div className="relative z-40">
        <svg
          style={svgStyleTop}
          className=""
          width="110%"
          viewBox="0 0 940 80"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon className="fill-dark " points="1241.7,0 0,77.1 0,0 " />
        </svg>
      </div>
      <HomeAboutBlock className="relative z-40" />
      <div className="relative">
        <svg
          style={svgStyleBottom}
          className="right-0"
          width="100%"
          viewBox="0 0 940 81"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            className="fill-dark"
            points="0,81.1 1241.7,4 1241.7,81.1 "
          />
        </svg>
      </div>
      <SpeedAgility className="relative z-40" />
      <div className="relative z-8">
        <svg
          style={svgStyleTop}
          className="left-0"
          width="100%"
          viewBox="0 0 940 80"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon className="fill-dark" points="1241.7,0 0,77.1 0,0 " />
        </svg>
      </div>
      <ServicesHome className="z-10" />
      <div className="container py-8 px-8">
        <FAQDropdown title={"Exercise for children and teenagers"}>
          <p>
            Exercise is crucial for children and teenagers, offering profound
            benefits that support their overall development, health, and
            well-being. Regular physical activity fosters healthy growth by
            enhancing bone density, muscle strength, and cardiovascular fitness,
            which can reduce the risk of chronic diseases like obesity,
            diabetes, and heart conditions. Additionally, exercise boosts the
            immune system and improves motor skills, ensuring better physical
            health and resilience against illnesses. Beyond physical benefits,
            exercise significantly enhances mental health by reducing symptoms
            of anxiety and depression and improving cognitive functions such as
            memory and concentration. It also fosters better sleep patterns and
            instills a sense of discipline and responsibility.
          </p>
          <p>
            Moreover, exercise plays a vital role in social development.
            Engaging in sports and group activities promotes teamwork, social
            interaction, and the development of friendships, all of which are
            crucial for building self-esteem and confidence. These activities
            teach valuable life skills, such as cooperation and time management,
            and contribute to reducing behavioral issues. Establishing a routine
            of regular physical activity from a young age lays the foundation
            for a healthy lifestyle, promoting long-term health and well-being.
            Encouraging diverse and enjoyable forms of exercise ensures that
            children and teenagers not only stay physically active but also
            enjoy the process, leading to a lifetime of good health habits.
          </p>
        </FAQDropdown>
        <FAQDropdown title={"Agility in sports"}>
          <p>
            Agility is a critical component in sports, providing athletes with
            the ability to quickly change direction, accelerate, and decelerate
            while maintaining balance and control. This ability is essential for
            enhancing performance, as it allows for rapid responses to dynamic
            situations such as opponents' movements or sudden changes in the
            ball's direction, crucial in sports like soccer, basketball, and
            tennis. Efficient movement facilitated by agility helps conserve
            energy, allowing athletes to maintain high performance levels over
            extended periods, which is vital in endurance sports and those
            requiring short, explosive bursts of speed like football and hockey.
            Additionally, agility training reduces the risk of injuries by
            improving balance, coordination, and joint stability, which are
            crucial for safely executing movements in sports that involve
            frequent cutting, jumping, and sudden stops.
          </p>
          <p>
            Beyond its physical benefits, agility provides a competitive edge by
            enabling skillful maneuvering and quick transitions between
            offensive and defensive roles. This versatility is particularly
            valuable in team sports where navigating through opponents or
            adjusting tactics swiftly can lead to successful plays and scoring
            opportunities. Agility also enhances athletes' confidence and mental
            flexibility, fostering better decision-making and adaptability in
            fast-paced game situations. Importantly, agility is a versatile
            skill that benefits athletes across various sports, from track and
            field to team sports, and is especially crucial for youth
            development. Developing agility in young athletes lays a foundation
            for mastering complex skills and movements, contributing to
            long-term athletic success and overall physical literacy.
          </p>
        </FAQDropdown>
        <FAQDropdown title={"Strength and conditioning"}>
          <p>
            Strength and conditioning are fundamental aspects of athletic
            training that aim to enhance overall physical performance, reduce
            the risk of injury, and improve health and well-being. These
            training disciplines are not just limited to athletes; they benefit
            individuals across all fitness levels and age groups by improving
            strength, endurance, flexibility, and functional capacity. Strength
            training typically involves exercises designed to increase muscle
            mass, strength, and power, using resistance like weights or
            bodyweight exercises. Conditioning, on the other hand, focuses on
            improving cardiovascular endurance, muscular endurance, and overall
            physical fitness, often through aerobic and anaerobic exercises like
            running, cycling, or high-intensity interval training (HIIT).
          </p>
          <p>
            Integrating strength and conditioning into a regular exercise
            regimen offers numerous benefits. Firstly, it enhances athletic
            performance by increasing an athlete's ability to generate force and
            power, crucial for sports that require explosive movements like
            sprinting, jumping, and lifting. Improved conditioning boosts
            stamina and recovery rates, enabling athletes to perform at higher
            intensities for longer periods. Secondly, strength and conditioning
            are pivotal in injury prevention. By strengthening muscles, tendons,
            and ligaments, and improving joint stability and flexibility, these
            exercises help mitigate the risk of injuries commonly associated
            with physical activities. Additionally, strength and conditioning
            contribute to better body composition and overall health, promoting
            metabolic efficiency, cardiovascular health, and functional
            mobility, which are vital for maintaining an active and healthy
            lifestyle throughout life.
          </p>
        </FAQDropdown>
      </div>
    </div>
  );
}

//export default Home;
