import React, {useState} from 'react'
import PostCard from './PostCard'


const Pagination = ({items, itemsPerPage}) => {

    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = items.data.slice(indexOfFirstItem, indexOfLastItem);

    
    return(
   
        <div class="container mx-auto">
                
                <div class="container w-100 lg:w-4/5 mx-auto flex flex-col">
        {currentItems.map(post => (
        <div key={post.id}>

           <PostCard post={post} />
       </div>
       ))}
       </div>
       </div>
    
    )
}

export default Pagination

