import React from 'react'
import {
  BrowserRouter as Router,
  Switch, 
  Route, 
} from "react-router-dom";
import ScrollToTop from "./Hooks/ScrollToTop";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./components/views/About";
import Home from "./components/views/Home";
import Posts from "./components/Pages/Posts";
import Post from "./components/views/Post";
import Cases from "./components/views/Cases";
import Case from "./components/views/Case";
import Contact from "./components/views/Contact";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="relative min-h-screen overflow-hidden">
      <Router>
        <Header />
        <Helmet>
          <title>EES Fitness</title>
          <meta
            name="description"
            content="We specialise in branding, web design, graphic design, social media campaigns and SEO. We know what it takes to create long-lasting meaningful brands and memorable marketing campaigns."
          />
        </Helmet>
        <ScrollToTop>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/Contact">
              <Contact />
            </Route>
            <Route path="/posts/:pagenumber/:catId">
              <Posts />
            </Route>
            <Route path="/post/:id">
              <Post />
            </Route>
            <Route path="/cases/:pagenumber">
              <Cases />
            </Route>
            <Route path="/case/:id">
              <Case />
            </Route>
          </Switch>
        </ScrollToTop>

        <Footer />
      </Router>
    </div>
  );
}
export default App;
