import React, { useState } from "react";
import PostFeaturedCard from "./PostFeaturedCard";
import PostSmallFeaturedCard from "./PostSmallFeaturedCard";
import { useAxiosGet } from "../../../Hooks/httpRequest";

const Pagination = ({ items, itemsPerPage }) => {
  const [currentPage, setCurrentPage, firstItems] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.data.slice(indexOfFirstItem, indexOfLastItem);
  return (
    <div class="">
      <div class="container w-100 mx-auto">
        <div className="grid grid-cols-12 gap-2">
          {currentItems.slice(0, 1).map((post) => (
            <div className="col-span-12" key={post.id}>
              <PostFeaturedCard post={post} />
            </div>
          ))}

          {currentItems.slice(1, 3).map((post) => (
            <div className="col-span-12 md:col-span-6" key={post.id}>
              <PostSmallFeaturedCard post={post} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pagination;
