import React, {useState} from 'react'
import { useTransition, animated } from 'react-spring'
import NavigationMenu from './NavigationMenu'
import DesktopNav from './DesktopNav'
import { default as MenuBurger } from '../images/menu/menu.svg'


function Navigation() {
    const [showMenu, setShowMenu] = useState(false)

    const maskTransitions = useTransition(showMenu, {
      from: { position: 'absolute', opacity: 0, transform:'translateX(-100%)' },
      enter: { opacity: 1, transform:'translateX(0%)'},
      leave: { opacity: 1, transform:'translateX(-100%)'},
      //delay: 100,
      //config: config.molasses,
      //onRest: () => set(!showMenu),
    })

  if (showMenu == true) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }

    const menuTransitions = useTransition(showMenu, {
        from: { opacity: 0, transform:'translateX(-100%)' },
        enter: { opacity: 1, transform:'translateX(-2%)' },
        leave: { opacity: 1, transform:'translateX(-100%)'},
      delay: 50,
      
        //config: config.molasses,
        //onRest: () => set(!showMenu),
      })

  return (
      <>
      <nav>
        <DesktopNav />
        <button className="mt-4 mr-8 md:hidden" onClick={() => setShowMenu(!showMenu)}>
          
          <img width="30px" src={MenuBurger} />
            </button>
              
      </nav>

      { 
            
            maskTransitions(
                (styles, item) => item && 
                <animated.div 
                style={styles}
                    className="fixed bg-primary top-0 left-0 w-full h-screen z-50"
                   onClick={() => setShowMenu(false)}
                   >
                </animated.div>
              )

            }
            
            
            { 
            
            menuTransitions(
                (styles, item) => item && 
                <animated.div 
                style={styles} 
                className="fixed gradient bg-gradient-to-r from-black to-darklt top-0 left-0 w-full h-full z-50 shadow p-3"
                >     
            
            
            <NavigationMenu closeMenu={()=>setShowMenu(false)} />
             
                </animated.div>
              )

            }

  
      </>
    )
}
export default Navigation
