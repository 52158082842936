import React, {useState, useEffect} from 'react';
import axios from 'axios'

export function axiosSendEmail(url, formValue){

    
    const emailIN = formValue.email;
    const fullNameIN = formValue.firstName +' '+formValue.lastName ;
    //const lastNameIN = formValue.lastName;
    const subjectIN = 'I would like an appointment '+fullNameIN;
    const bodyIN = 'I would like an appoinment on the ' + formValue.date + ', at ' + formValue.hour + ':' + formValue.minute + ' ' + formValue.pm + '. You can phone me: '+formValue.phone+' or email me at: ' +formValue.email+'.';

    axios.get(url, {params:{
        email: emailIN,
        subject: subjectIN,
        name: fullNameIN,
        body: bodyIN,}
      })
      .then((response) => {
        return response;
      });
}