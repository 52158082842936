import React, { useState } from "react";
import GetPosts from "../views/Posts/GetPosts";
import GetFeaturedPosts from "../views/Posts/GetFeaturedPosts";
import { useAxiosGet } from "../../Hooks/httpRequest";
import PaginationNav from "../../Hooks/PaginationNav";
import { useParams } from "react-router-dom";
import TopBanner from "../TopBanner";
import CategoryLoop from "../../Hooks/CategoryLoop";
import { Helmet } from "react-helmet";

//import HelloWorld from '../HelloWorld'

function Posts(props) {
  const { pagenumber, catId } = useParams();

  const [page, setPage] = React.useState(pagenumber);

  const [currentCategory, setCurrentCategory] = React.useState(catId);

  function handleChange(newValue) {
    setPage(newValue);
  }

  function handleCat(newValue) {
    setCurrentCategory(newValue);
  }

  console.log(page);

  console.log(currentCategory);

  const svgStyleTop = {
    position: "absolute",
    color: "white",
    width: "100%",
    top: "-1px",
  };

  const itemsPerPage = 10;

  const categories = `&categories=${currentCategory}`;
  const categorcount = `?categories=${currentCategory}`;

  const url = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/wp/v2/posts?page=${page}${categories}`;
  const totalQty = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/posts/v1/getpostqty${categorcount}`;

  let posts = useAxiosGet(url);
  let totalItems = useAxiosGet(totalQty);

  let content = null;
  let featured = null;

  if (posts.error) {
    content = (
      <div class="pt-24 flex items-center justify-center space-x-2">
        <h2>Opps, Looks like there is an error</h2>
      </div>
    );
  }

  if (posts.loading) {
    content = (
      <div class="pt-24 flex items-center justify-center space-x-2">
        <div
          class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
          role="status"
        >
          <span class="visually-hidden">L</span>
        </div>
      </div>
    );
  }

  //Get current post

  //const paginate = (pageNumber) => setCurrentPage(pageNumber)
  //console.log(currentPage)

  if (posts.data) {
    content = (
      <GetPosts
        items={posts}
        loading={posts.loading}
        currentPage={posts.currentPage}
        itemsPerPage={posts.itemsPerPage}
        loopFrom={0}
        loopTo={3}
      />
    );
  }

  if (posts.data) {
    featured = (
      <GetFeaturedPosts
        items={posts}
        loading={posts.loading}
        currentPage={posts.currentPage}
        itemsPerPage={posts.itemsPerPage}
        loopFrom={4}
        loopTo={12}
      />
    );
  }

  return (
    <div className="bg-content">
      <Helmet>
        <title>News Articles</title>
        <meta
          header="News Articles"
          topHeader="latest News"
          metaDescription="Latest news articles from Plain Design Studio"
        />
      </Helmet>

      <div className="container-fluid top-0 relative bg-dark z-8 overflow-hidden">
        <div className="relative">
          <div
            className="w-100 hidden top-0 backgroundImage absolute bg-gradient-to-t from-dark"
            style={{
              height: "75vh",
            }}
          ></div>
          <div className="w-100 relative">
            <div class="container" style={{ minHeight: "50vh" }}>
              <div class="pt-28">
                <h1 className="relativez-50 outlineText text-6xl leading-tight">
                  EES Articles
                </h1>
                <div className="grid grid-cols-12 gap-0 py-8">
                  <div className="col-span-12 relative">{featured}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="h-50 w-full overflow-clip hidden">
        <div className="relative top-0 bg-light w-100 py-12 h-50">
          <div className="relative container px-4 flex flex-wrap md:flex-row-reverse">
            <div className="w-full md:w-8/12 lg:w-8/12 mx-auto text-center relative">
              <div className="relative text-white md:my-24 my-8">
                <div className="rounded-xl p-8">
                  <h6 class="text-primary">{props.topHeader}</h6>
                  <h1 className="relativez-50 text-dark md:text-4xl lg:text-4xl xl:text-5xl leading-tight font-heading">
                    {props.header}
                    <div className="hidden relative" width="100px">
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 2000 100"
                        preserveAspectRatio="xMaxYMax"
                      >
                        <g>
                          <path
                            className="fill-primary"
                            d="M35.7,42.3C159.7,20.2,285.4,8.6,411.1,3.6C505.5,0.1,600.2,0.5,694.4,8c30.8,2.3,63.5,5.8,94.1,9.7
		c25.4,4,21.6,40.8-4.5,39.1C629.7,41.9,474,37.6,319,45.3C230,49.5,140.5,57,52.1,69c-3.9,0.5-7.8,1.1-11.5,1.7
		C22.2,72.8,17.3,46.7,35.7,42.3L35.7,42.3z"
                          />
                        </g>
                      </svg>
                    </div>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative">
        <div className="container">
          <div className="grid grid-cols-12 gap-8">
            <div className="col-span-12 relative">{content}</div>
            <div className="md:col-span-3 col-span-12 relative hidden">
              <CategoryLoop
                onChange={handleCat}
                loopArgs="posts"
              ></CategoryLoop>
            </div>
          </div>
        </div>
      </div>

      <PaginationNav
        onChange={handleChange}
        totalItems={totalItems.data}
        itemsPerPage={itemsPerPage}
        currentPage={pagenumber}
        currentCat={currentCategory}
      />
    </div>
  );
}

export default Posts;
