import React, { Component } from "react";
import FormUserDetails from "./FormUserDetails";
import FormPersonalData from "./FormPersonalData";
import FormAppointment from "./FormAppointment"
import Confirm from "./Confirm";
import Submitted from "./Submitted";
import { axiosSendEmail } from "../../../Hooks/axiosSendEmail";

export class UserForm extends Component {

   url = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/awhitepixel/v1/getsomedata/`
    
  state = {
    step: 1,
    firstName: "",
    lastName: "",
    email: "",
    city: "",
    date: "",
    time: "01",
    phone: "",
    pm: "AM",
    hour: "01",
    minute: "00",

  };

  //Go next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  //Go back to prev
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };
    
      //Go back to prev
    setNewDate = (e) => {
    const { date } = this.state;
     //   console.log(e);
    //const { date } = this.state;
     this.setState({
             date: "'"+e+"'",
       });
    };
    

    submitEmail = (e) => {
        console.log(this.url, e);
        axiosSendEmail(this.url, e);
        const { step } = this.state;
        this.setState({
          step: step + 1,
        });
       }
    
    
  //Handle fields change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  render() {
      const { step } = this.state;
    const { firstName, lastName, email, city, hour, minute, pm, time, phone, date} = this.state;
    const values = {
      firstName,
      lastName,
      email,
      city,
      hour,
      minute,
        time,
      pm,
        phone,
        date

    };

    switch (step) {
      case 1:
        return (
          <FormUserDetails
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 2:
        return (
          <FormPersonalData
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      // case 3:
      //   return (
      //       <FormAppointment
      //           setNewDate={this.setNewDate}
      //           prevStep={this.prevStep}
      //           nextStep={this.nextStep}
      //           handleChange={this.handleChange}
      //           values={values}
      //       />
      //     );

      // case 3:
      //   return (
      //     <Confirm
      //       prevStep={this.prevStep}
      //       submitEmail={this.submitEmail}
      //       values={values}
      //     />
      //   );

      case 3:
        return (
          <Submitted
            prevStep={this.prevStep}
            submitEmail={this.submitEmail}
            values={values}
          />
        );
    }
  }
}

export default UserForm;
