import React from "react";
import UserForm from "./Contact/UserForm";
import { default as cross } from '../images/assets/triangle.svg'
import { default as squiggle } from '../images/assets/sqiggle.svg'
import { useParallax } from "react-scroll-parallax";

function Contact() {

  const corossImage = {
    right: '-10%',
    top: '-10%'
  };

  const squiggleImage = {
    bottom: '-50px',
    left: '0px'
  }

  const triangleMove = useParallax({
    speed: 15,
    rotate: [-15, 140]
  });

  const squiggleMove = useParallax({
    speed: 0,
    translateX: [15, -40]
  });

  return (

    <div className="flex min-h-screen bg-dark relative">
      <div class="container lg:mt-60 mt-48 mb-48 lg:mb-60">
        <div class="grid grid-cols-12 gap-4 p-5">
          <div class="col-span-12 sm:col-span-12 md:col-span-12 lg:col-start-3 lg:col-span-8 xl:col-start-4 xl:col-span-6 relative">
          <h1 class="italic uppercase regular mb-0">Contact Form</h1>
          <img ref={triangleMove.ref} style={corossImage} width="100px" className="absolute" src={cross}></img>
      <img ref={squiggleMove.ref} style={ squiggleImage } width="200px" className="absolute" src={squiggle}></img>
            <UserForm />
          </div>
        </div>
      </div>
      </div>
   
  );
}

export default Contact;
