import React, { useEffect, useState} from 'react'
import Navigation from './Navigation/Navigation'
import { Link } from 'react-router-dom'
import { default as logo } from './images/logo/logo.svg'

function Header() {

     // storing this to get the scroll direction
  const [lastScrollTop, setLastScrollTop] = useState(0);
   // the offset of the document.body
  const [bodyOffset, setBodyOffset] = useState(
    document.body.getBoundingClientRect()
  );
   // the vertical direction
  const [scrollY, setScrollY] = useState(bodyOffset.top);
   // the horizontal direction
  const [scrollX, setScrollX] = useState(bodyOffset.left);
   // scroll direction would be either up or down
    const [scrollDirection, setScrollDirection] = useState();
    let colorChange = 'bg-none'
    let logotext = 'regular'

  console.log(scrollY);

  const listener = e => {
    setBodyOffset(document.body.getBoundingClientRect());
    setScrollY(-bodyOffset.top);
    setScrollX(bodyOffset.left);
    setScrollDirection(lastScrollTop > -bodyOffset.top ? "down" : "up");
    setLastScrollTop(-bodyOffset.top);
  };
    
    if (scrollY >  20 ) {
        colorChange = 'bg-dark';
        logotext = 'textNone'
    }
    if (scrollY < 50 || scrollY === 0) {
        colorChange = 'bg-none';
        logotext = 'regular'
    }

  useEffect(() => {

      window.addEventListener("scroll", listener);
    return () => {

        window.removeEventListener("scroll", listener);
    };
  });
    
  const styles = {
    active: {
      visibility: "visible",
      transition: "all 0.5s"
    },
    hidden: {
      visibility: "hidden",
      transition: "all 0.5s",
      transform: "translateY(-100%)"
    }
  }
    
    return (
      <header
        style={
          scrollDirection === "down" || scrollY <= 10
            ? styles.active
            : styles.hidden
        }
        className={`p-3 flex justify-between fixed w-full ${colorChange}`}
      >
        <span className="font-bold flex z-100 items-center justify-center">
          <Link className="flex h-10" to="/">
            <div style={{ width: "80px", marginRight: "8px" }}>
              <img width="100%" className="" src={logo} />
            </div>

            <div className={`relative logoTypeWrapper mt-3`}>
              <span
                className={`font-thin text-white logoType relative align-middle ${logotext}`}
              >
                STRETCH
              </span>
            </div>
          </Link>
        </span>

        <Navigation />
      </header>
    );
}


export default Header