import React, { useState } from "react";
import { default as cross } from "../../images/assets/cross.svg";
import { default as squiggle } from "../../images/assets/sqiggle.svg";
import { default as triangle } from "../../images/assets/triangle.svg";
import { useParallax } from "react-scroll-parallax";
import { default as borderIMG } from "../../images/assets/border.svg";
import { default as borderLeftIMG } from "../../images/assets/borderLeft.svg";
import { useSpring, animated } from "@react-spring/web";
import football from "../../images/assets/pngwing.com.png";
import kickboxing from "../../images/assets/kickboxer.png";
import "../../css/assets.css";
import "../../css/bgImages.css";

function Flexibility() {
  const bgImage = {
    backgroundImage: `url(${football})`,
  };
  const bgkickboxing = {
    backgroundImage: `url(${kickboxing})`,
    bottom: "0px",
  };

  const triangleImage = {
    left: "-50px",
    top: "-50px",
    transform: "rotate(40deg)",
  };

  const squiggleImage = {
    right: "50px",
    bottom: "50px",
  };

  const triangleRotate = useParallax({
    rotate: [0, 80],
    speed: -8,
  });

  return (
    <div>
      <section className="body-font text-white bg-darkmd z-10">
        <div className="container px-5 py-24 mx-auto relative">
          <img
            ref={triangleRotate.ref}
            style={triangleImage}
            width="150px"
            className="absolute"
            src={triangle}
          ></img>
          <img
            style={squiggleImage}
            width="160px"
            className="absolute"
            src={squiggle}
          ></img>
          <div className="flex flex-col text-center w-full mb-20">
            <h2 className="text-3xl lg:text-5xl mb-3 uppercase italic leading-tight font-heading">
              Wanna see what<span className="text-secondary"> we do?</span>
            </h2>
          </div>

          <div class="grid grid-cols-12">
            <div class="lg:col-span-10 lg:col-start-2 col-span-12">
              <div className="flex flex-wrap m-4">
                <div className="relative rounded-xl h-full bg-darklt p-0 shadow">
                  <div class="flex flex-col-reverse md:grid md:grid-cols-12">
                    <div class="md:col-span-6 col-span-12 text-light p-8">
                      <div className="flex items-center mb-3">
                        <h2 class="mb-0 text-light uppercase leading-tight text-xl">
                          Strength & Conditioning
                        </h2>
                      </div>

                      <p class="leading-relaxed text-lg">
                        Blue bottle crucifix vinyl post-ironic four dollar toast
                        vegan taxidermy. Gastropub indxgo juice poutine.
                      </p>
                      <a class=" text-white inline-flex items-center">
                        <button class="text-dark mt-5 group inline-block  bg-none py-2 text-primary font-medium text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
                          Get Ripped!
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="inline-block h-8 w-10 ml-2 group-hover:translate-x-5 transition transition-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                          </svg>
                        </button>
                      </a>
                    </div>
                    <div class="md:col-span-6 col-span-12 text-dark bottom-0 right-0 backgroundWrapper">
                      <div className="h-full w-full relative">
                        <div
                          className="rounded-t-xl absolute 
                      overflow-clip right-0 bottom-0 h-full w-full
                      lg:bg-darklt
                      "
                        >
                          <img
                            className="h-full w-full bottom-0 right-0"
                            src={borderIMG}
                          ></img>
                        </div>
                        <div
                          className="z-8 backgroundImage"
                          ref={football.ref}
                          style={bgImage}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-12">
            <div class="lg:col-span-10 lg:col-start-2 col-span-12">
              <div className="flex flex-wrap m-4">
                <div className="relative rounded-xl h-full bg-darklt p-0 shadow">
                  <div class="flex flex-col-reverse grid grid-cols-12">
                    <div class="md:col-span-6 col-span-12 text-dark bottom-0 right-0 backgroundWrapper">
                      <div className="h-full w-full relative">
                        <div
                          className="rounded-t-xl absolute 
                      overflow-clip left-0 bottom-0 h-full w-full
                      lg:bg-darklt
                      "
                        >
                          <img
                            className="h-full w-full bottom-0 left-0"
                            src={borderLeftIMG}
                          ></img>
                        </div>
                        <div
                          className="z-8 backgroundImage left-0"
                          ref={bgkickboxing.ref}
                          style={bgkickboxing}
                        ></div>
                      </div>
                    </div>

                    <div class="md:col-span-6 col-span-12 text-light p-8">
                      <div className="flex items-center mb-3">
                        <h2 class="mb-0 text-light uppercase leading-tight text-xl">
                          Speed & Agility
                        </h2>
                      </div>

                      <p class="leading-relaxed text-lg">
                        Blue bottle crucifix vinyl post-ironic four dollar toast
                        vegan taxidermy. Gastropub indxgo juice poutine.
                      </p>
                      <a class=" text-white inline-flex items-center">
                        <button class="text-dark mt-5 group inline-block  bg-none py-2 text-primary font-medium text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
                          Get Ripped!
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="inline-block h-8 w-10 ml-2 group-hover:translate-x-5 transition transition-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                          </svg>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Flexibility;
