import React from "react";
import { Reveal } from "../../../Hooks/Reveal";
import { Link } from "react-router-dom";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";
import { default as cross } from "../../images/assets/cross.svg";
import { default as squiggle } from "../../images/assets/sqiggle.svg";
import { useSpring, animated, config, easings } from "react-spring";

function AboutIntro() {
  const crossStyle = {
    position: "absolute",
    width: "40%",
    maxWidth: "150px",
    bottom: "-40px",
    left: "-20px",
  };

  return (
    <div className="bg-darklt relative z-10">
      <section className="container relative mx-auto px-4 z-10 md:py-12 lg:py-12 py-12  text-center lg:text-left">
        <div className="flex flex-col lg:flex-row">
          <div className="mb-8 lg:mb-0 mx-auto px-0 md:px-12 lg:basis-2/5 md:basis-1/2 basis-1/1 relative"></div>

          <div className="px-0 py-4 md:px-2 md:py-12 lg:basis-3/5 md:basis-1/2 basis-1/1">
            <ScrollEffect speed={-8}>
              <p class="mb-8 relative z-10">
                Welcome to EES Fitness! Elizabeth is a passionate personal
                trainer from Liverpool who grew up training as an elite gymnast.
                Her love of sport and health led her to pursue a career in
                fitness, where she now helps others achieve their fitness goals
                through personalized training and guidance.
              </p>
              <p class="mb-8 relative z-10">
                With years of experience in the fitness industry, Elizabeth is a
                certified personal trainer who is dedicated to helping her
                clients reach their full potential. Her approach is grounded in
                science-based principles, and she tailors each client's program
                to their specific needs and goals. Whether you're a beginner or
                an experienced athlete, Elizabeth can help you take your fitness
                to the next level.
              </p>

              <p class="mb-8 relative z-10">
                Elizabeth's diverse background in sport and health means she has
                expertise in many areas, including weight training, cardio,
                flexibility, and more. She believes that a well-rounded approach
                to fitness is key to achieving long-term success, and she
                incorporates a variety of exercises and techniques into her
                clients' programs to keep them engaged and motivated.
              </p>
            </ScrollEffect>
          </div>
        </div>
      </section>
      <ScrollEffect rotateFrom={-10} rotateTo={0} XFrom={0} XTo={0}>
        <div style={crossStyle} className="absolute z-10 md:block hidden">
          <AnimatedAsset
            duration={53000}
            rotate={15}
            minusrotate={-300}
            posx={-16}
            minusx={22}
            posy={30}
            minusy={-40}
          >
            <img className="" alt="Cartoon Coin mascot" src={cross} />
          </AnimatedAsset>
        </div>
      </ScrollEffect>
    </div>
  );
}

export default AboutIntro;
