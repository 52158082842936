import React from "react";
import { Link } from "react-router-dom";
import { useAxiosGet } from "../../../Hooks/httpRequest";
import { useParallax } from "react-scroll-parallax";

function PostCard(props) {
  
  const { title, excerpt, featured_media, id, date } = props.post;

  const url = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/wp/v2/media/${featured_media}`;

  const newdate = new Date(Date.parse(date));
  const day = newdate.toLocaleString('default', { day: '2-digit' });
  const month = newdate.toLocaleString('default', { month: 'short' });
  const year = newdate.toLocaleString('default', { year: 'numeric' });
  const formatteddate = day + ', ' + month + ' ' + year;

  const image = useAxiosGet(url);

  let FeaturedImage = null;

  if (image.error) {
    FeaturedImage = (
      <div class="flex items-center justify-center space-x-2">
        <h2>Opps, Looks like there is an error</h2>
      </div>
    );
  }

  if (image.loading) {
    FeaturedImage = (
      <div class="flex items-center justify-center space-x-2">
        <div
          class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
          role="status"
        >
          <span class="visually-hidden">L</span>
        </div>
      </div>
    );
  }

  //Get current post


  if (image.data) {
    FeaturedImage = image.data.media_details.sizes.full.source_url;
  }

  const postCard = useParallax({
    //rotate: [-2, 5],
    //translateX: ['-5', '10'],
  });

  return (
  
    <div ref={postCard.ref} v-for="card in cards" class="flex flex-col md:flex-row overflow-hidden
                                      bg-light rounded-lg shadow-xl  mt-8 w-100 mx-8 md:mx-2 transform transition duration-500 hover:scale-105">
      
      <div class=" xl:h-100 lg:h-100 h-50 w-auto md:w-1/2">
      <Link to={`/case/${id}`}>
          <img class="inset-0 h-full w-full object-cover object-center" src={FeaturedImage}></img>
          </Link>
      </div>
    
      <Link class="w-full py-8 px-6 flex flex-col justify-between text-dark" to={`/case/${id}`}>
        <h3 class="uppercase text-2xl outLineText leading-tight ">{title.rendered}</h3>
          <p class="text-sm italic outLineText leading-tight truncate">
            {formatteddate}
          </p>
        <p className="text-base taxt-darklt"
          
            dangerouslySetInnerHTML={{ __html: excerpt.rendered }}
          ></p>
      
          <button class="mt-5 text-left group inline-block bg-none py-2 text-dark font-medium text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
     Read more
     <svg
       xmlns="http://www.w3.org/2000/svg"
       class="inline-block h-8 w-10 ml-2 group-hover:translate-x-5 transition transition-500"
       fill="primary"
       viewBox="0 0 24 24"
       stroke="currentColor"
     >
       <path
         stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="2"
         d="M17 8l4 4m0 0l-4 4m4-4H3"
       />
     </svg>
            </button>
            </Link>
       
      </div>
 
  );
}

export default PostCard;
