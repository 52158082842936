import React from 'react';
import TopBanner from "../TopBanner";
import AboutIntro from "../views/About/AboutIntro";
import Values from "../views/About/Values";

function About() {
  const svgStyleTop = {
    position: "absolute",
    color: "white",
    width: "100%",
    top: "-1px",
  };

  const svgStyleBottom = {
    position: "absolute",
    color: "white",
    width: "100%",
    bottom: "-1px",
  };

  return (
    <>
      <TopBanner header="About EES Fitness" />
      <div className="relative z-8">
        <svg
          style={svgStyleBottom}
          className="right-0"
          width="100%"
          viewBox="0 0 940 81"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            className="fill-darklt"
            points="0,81.1 1241.7,4 1241.7,81.1 "
          />
        </svg>
      </div>
      <AboutIntro />
      <Values />
    </>
  );
}

export default About