import React from "react";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { default as cross } from "../../images/assets/cross.svg";
import { default as citBg } from "../../images/assets/liverpool-night.jpg";
import { default as JumpingMan } from "../../images/assets/man-running-sm.png";

import { useParallax } from "react-scroll-parallax";

export default function ServicesHome() {
  const corossImage = {
    left: "-50px",
    top: "-50px",
  };

  const JumpingManStyle = {
    top: "-20px",
    margin: "0 auto",
  };

  const bgImageStyle = {
    backgroundImage: `url(${citBg})`,
    height: "100%",
    width: "100%",
    position: "absolute",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    opacity: 2,
    filter: "blur(10px) opacity(0.3)",
  };

  const textTitleStyle = {};

  return (
    <div className="text-white bg-darkmd overflow-hidden relative">
      <section className="container mx-auto py-24  md:py-44 lg:py-44">
        <div class="flex items-center flex-wrap">
          <div class="bg-dark w-full lg:w-1/2 shadow-xl rounded-xl relative align-content-center">
            <div className="absolute w-full h-100">
              <ScrollEffect
                speed={-1.5}
                XFrom={-0}
                XTo={-0}
                YFrom={-10}
                YTo={10}
              >
                <img
                  style={JumpingManStyle}
                  width="100%"
                  className="mx-auto"
                  src={JumpingMan}
                ></img>
              </ScrollEffect>
            </div>

            <img src={citBg} className="opacity-8" alt="responsive"></img>
          </div>
          <div class="w-full lg:w-1/2 px-12 mt-12">
            <h4 class="text-3xl lg:text-5xl mb-3 uppercase italic leading-none font-heading">
              About EES Fitness
            </h4>
            <p class="mb-8">
              Our training programs focus on four key areas: agility, strength &
              conditioning, speed, and stamina. Agility training helps you
              develop the ability to move quickly and change direction with
              precision, while strength & conditioning is crucial for building
              the physical foundation needed for success in any sport or
              activity. Speed training helps you increase your overall speed,
              while stamina training helps you build endurance and stay at the
              top of your game for longer periods of time.
            </p>
          </div>
        </div>
      </section>
      <div style={bgImageStyle}></div>
    </div>
  );
}
