import React from "react";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";

export const RollingBanner = () => (
    <div className="gradient bg-gradient-to-r from-dark to-darklt">
        <Marquee gradient={false} speed={200} marquee={{ gradient: false }}>
 <h3 className="m-2 pt-5 text-3xl text-white uppercase ">Start your fitness journey today! <span className="outlineText uppercase">Sign up to EES Fitness Today</span></h3>
        </Marquee>

        <div className="text-center pt-2 pb-10">
        < Link to="/Contact" 
      className=""
            >
            <button class="font-bold bg-primary text-dark px-8 mt-5 group inline-block hover:bg-secondary bg-none py-2 text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
                    Join Now!
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="inline-block h-8 w-10 ml-2 group-hover:translate-x-5 transition transition-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                </button>
                </Link>
        </div>
        </div>
);

//export default RollingBanner;