import React, { Component } from "react";

export class FormUserDetails extends Component {
    continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };
    
   


    render() {
      
    let nextButton;
        
    const { values, handleChange } = this.props;
    
     if(values.lastName && values.firstName){
         nextButton = <button onClick={this.continue} class="text-dark mt-5 group inline-block  bg-none py-2 text-primary font-medium text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
     That's my name
     <svg
       xmlns="http://www.w3.org/2000/svg"
       class="inline-block h-8 w-10 ml-2 group-hover:translate-x-5 transition transition-500"
       fill="none"
       viewBox="0 0 24 24"
       stroke="currentColor"
     >
       <path
         stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="2"
         d="M17 8l4 4m0 0l-4 4m4-4H3"
       />
     </svg>
   </button>
     }

      return (
      
      <div className="w-full bg-dark">
        <h5>My name is...</h5>
        <div class="flex justify-center w-full">
        <div className="w-full">
            <div class="form-floating mb-3 w-full">
              <input
                type="text"
                onChange={handleChange("firstName")}
                defaultValue={values.firstName}
                className="form-control
                                block
                                w-full
                                px-3
                                py-2
                                text-md
                                font-normal
                                text-white
                                bg-dark bg-clip-padding
                                transition
                                ease-in-out
                                m-0
                                border-b-4 border-b-primary
                                focus:text-white focus:border-y-0 focus:outline-primary"
               
                placeholder="First name"
              />
              <label  className="leading-4 text-md text-gray-400 ">
               First name
              </label>
            </div>
            <div class="form-floating mb-3 w-full">
              <input
                type="text"
                onChange={handleChange("lastName")}
                defaultValue={values.lastName}
                className="form-control
                block
                w-full
                px-3
                py-2
                text-md
                font-normal
                text-white
                bg-dark bg-clip-padding
                transition
                ease-in-out
                m-0
                border-b-4 border-b-primary
                focus:text-white focus:border-y-0 focus:outline-primary"
                id="floatingPassword"
                placeholder="Password"
              />
              <label for="floatingPassword" class="leading-4 text-md text-gray-400 ">
                Last name
              </label>
            </div>
                    <div class="flex space-x-2 justify-end ">
                        {nextButton}
         
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormUserDetails;
