import React from 'react';
import PostContent from './Posts/PostContent'
import "../css/wppost.css";
import { useParams } from 'react-router-dom';
import { useAxiosGet } from '../../Hooks/httpRequest';

function Product(){
    const { id } = useParams()
    const url = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/wp/v2/posts/${id}`

    let content = null

    let product = useAxiosGet(url);

    if(product.error){
        content = <div class="flex items-center justify-center space-x-2">
        <h2>Opps, Looks like there is an error</h2>
      </div>
    }

    if(product.loading){
        content = <div class="pt-32 flex items-center justify-center space-x-2">
        <div class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full" role="status">
          <span class="visually-hidden">L</span>
        </div>
      </div>
    }



    if(product.data){
    return(
        content = <PostContent post={product.data} />
    )
    }
    
    return(

        <div>

            {content}
 
        </div>
    )

}


export default Product
