import React, { useState } from "react";
import {
  useSpring,
  animated,
  easings,
  config,
  molasses,
  useSprings,
  useInView,
} from "@react-spring/web";
import useMeasure from "react-use-measure";
import { Children } from "react";

function FAQDropdown(props, children) {
  const text = [props.body];
  const title = [props.title];

  const [refHeight, height] = useMeasure();

  //var elHeight = height["height"];
  var HeightPx = `${height["height"]}px`;
  console.log(HeightPx);
  const [subMenuClicked, setSubMenuClicked] = useState(0);

  const toggleIsClicked = () => {
    // 👇️ passed function to setState
    setSubMenuClicked((current) => !current);
    console.log(subMenuClicked);
  };

  const { showSubMenu } = useSpring({
    showSubMenu: subMenuClicked ? 1 : 0,
    from: {
      maxHeight: "0px",
    },
    config: { mass: 5, tension: 2000, friction: 200 },
  });

  return (
    <div className="text-dark">
      <h5
        className="flex items-center justify-between bg-light mb-4 py-4 px-4"
        onClick={toggleIsClicked}
      >
        {title}
        <animated.div
          style={{
            rotate: showSubMenu.to({
              range: [0, 1],
              output: [0, -135],
            }),
            width: "30px",
            height: "30px",
            margin: "auto 0.4em ",
          }}
        >
          <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 30 30">
            <g>
              <line
                fill="#FFFFFF"
                stroke="#000000"
                strokeWidth="1.5039"
                strokeLinecap="round"
                strokeMiterlimit="10"
                x1="1"
                y1="15"
                x2="29"
                y2="15"
              />
              <line
                fill="#FFFFFF"
                stroke="#000000"
                strokeWidth="1.5039"
                strokeLinecap="round"
                strokeMiterlimit="10"
                class="st0"
                x1="15"
                y1="1"
                x2="15"
                y2="29"
              />
            </g>
          </svg>
        </animated.div>
      </h5>

      <animated.div
        className={
          subMenuClicked
            ? "sub-menu overflow-hidden"
            : "sub-menu overflow-hidden"
        }
        style={{
          maxHeight: showSubMenu.to({
            range: [0, 1],
            output: ["0px", `"${HeightPx}"`],
          }),
          color: showSubMenu.to({
            range: [0, 1],
            output: ["black hidden", "black block"],
          }),
        }}
      >
        <div class="mb-4 py-4 px-4" ref={refHeight}>
          {props.children}
        </div>
      </animated.div>
    </div>
  );
}
export default FAQDropdown;
