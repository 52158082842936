import React from "react";
import { Link } from "react-router-dom";
import { useAxiosGet } from "../../../Hooks/httpRequest";
import { useParallax } from "react-scroll-parallax";

function PostFeaturedCard(props) {
  const { title, excerpt, featured_media, id, date } = props.post;

  const url = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/wp/v2/media/${featured_media}`;

  const newdate = new Date(Date.parse(date));
  const day = newdate.toLocaleString("default", { day: "2-digit" });
  const month = newdate.toLocaleString("default", { month: "short" });
  const year = newdate.toLocaleString("default", { year: "numeric" });
  const formatteddate = day + ", " + month + " " + year;

  const image = useAxiosGet(url);

  let FeaturedImage = null;

  if (image.error) {
    FeaturedImage = (
      <div class="flex items-center justify-center space-x-2">
        <h2>Opps, Looks like there is an error</h2>
      </div>
    );
  }

  if (image.loading) {
    FeaturedImage = (
      <div class="flex items-center justify-center space-x-2">
        <div
          class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
          role="status"
        >
          <span class="visually-hidden">L</span>
        </div>
      </div>
    );
  }

  //Get current post

  if (image.data) {
    FeaturedImage = image.data.media_details.sizes.full.source_url;
  }

  const postCard = useParallax({
    //rotate: [-2, 5],
    //translateX: ['-5', '10'],
  });

  return (
    <div ref={postCard.ref} className="w-100">
      <div className="grid grid-cols-12 gap-4 mb-6 p-2">
        <div className="xl:col-span-7 lg:col-span-7 md:col-span-12 col-span-12 relative rounded-xl overflow-hidden">
          <Link to={`/post/${id}`}>
            <img
              class="inset-0 h-full w-full object-cover object-center transform transition duration-500 hover:scale-110"
              src={FeaturedImage}
            ></img>
          </Link>
        </div>

        <div className="xl:col-span-5 lg:col-span-5 md:col-span-12 col-span-12 relative lg:py-8 py-0">
          <Link class="md:w-full w-2/3 py-1 px-2 text-white" to={`/post/${id}`}>
            <p class="text-sm outLineText mb-2 leading-tight truncate">
              {formatteddate}
            </p>
            <h3 class="lg:text-4xl text-xl outLineText leading-tight ">
              {title.rendered}
            </h3>
            <p
              className="taxt-darklt mb-0"
              dangerouslySetInnerHTML={{ __html: excerpt.rendered }}
            ></p>
            <button class="mt-3 text-left group inline-block bg-none text-white font-medium text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
              Read more
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="inline-block h-8 w-10 ml-2 group-hover:translate-x-5 transition transition-500"
                fill="primary"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PostFeaturedCard;
