import React, { Component } from "react";

export class FormPersonalData extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };
    
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
      const { values, handleChange } = this.props;
      
      let nextButton;
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

          if (values.phone.length > 7 && re.test(values.email)) {

            nextButton = <button onClick={this.continue} class="text-dark group inline-block  bg-none py-2 text-primary text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
            Keep Going
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="inline-block h-8 w-10 ml-2 group-hover:translate-x-5 transition transition-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </button>
              
          }

    return (
      <div className="w-full">
        <h5 className="text-md">Can I have your email & number?</h5>
        <div class="flex justify-center">
          <div className="w-full">
            <div class="form-floating mb-3 w-full">
              <input
                type="email"
                onChange={handleChange("email")}
                defaultValue={values.email}
                className="form-control
                            block
                            w-full
                            px-3
                            py-2
                            text-md
                            font-normal
                            text-white
                            bg-dark bg-clip-padding
                            transition
                            ease-in-out
                            m-0
                            border-b-4 border-b-primary
                            focus:text-white focus:border-y-0 focus:outline-primary"
                id="floatingEmaile"
                placeholder="Email"
              />
              <label
                for="floatingEmaile"
                class="leading-4 text-md text-gray-400 "
              >
                email
              </label>
            </div>
            <div class="form-floating mb-3 w-full">
              <input
                type="number"
                onChange={handleChange("phone")}
                defaultValue={values.phone}
                className="form-control
                block
                w-full
                px-3
                py-2
                text-md
                font-normal
                text-white
                bg-dark bg-clip-padding
                transition
                ease-in-out
                m-0
                border-b-4 border-b-primary
                focus:text-white focus:border-y-0 focus:outline-primary"
                id="floatingPhone"
                placeholder="Phone"
              />
              <label
                for="floatingPhone"
                class="leading-4 text-md text-gray-400 "
              >
                phone
              </label>
            </div>
            <div class="flex space-x-5 justify-between">
              <button
                onClick={this.back}
                class="group inline-block  bg-none py-2 text-light text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="inline-block h-6 w-6 group-hover:translate-x-2 transition delay-100 transition-200"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  style={{ transform: "rotate(180deg)" }}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </button>

              {nextButton}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormPersonalData;