import React, { Component } from "react";
import { default as envelope } from '../../images/assets/envelope.svg'
import { Link } from "react-router-dom";

export class Confirm extends Component {

  submit = (e) => {
    e.preventDefault();
      //Process Form
    this.props.submitEmail(this.props.values);
  };
    
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };
    
    render() {

        let emailSet;
        let phoneSet;
        let hourSet;
        let minuteSet;
        let pmSet;

      const { values: { firstName, lastName, email, city, date, time, phone, hour, minute, pm } } = this.props;
      if (email) {
          emailSet = <li>{email}</li>
      }
      if (phone) {
        phoneSet = <li>{phone}</li>
      }
      if (hour) {
        hourSet = <span>{hour}</span>
      }
      if (minute) {
        minuteSet = <span>{minute}</span>
      }
      if (pm) {
        pmSet = <span>{pm}</span>
      }
    return (
      <div className="">
        <h1 className="text-md">Thank you for <br /><span className="outlineText">your request {firstName}</span></h1>
        <img className="hidden mx-auto mb-5" width="80px" src={envelope} />
 
      < Link to="/Posts/1" 
        ><button type="button" className="inline-block px-6 py-2 border-2 border-primary bg-primary text-dark font-medium text-xs leading-tight uppercase rounded-full hover:border-secondary hover:bg-secondary hover:text-white focus:outline-none focus:ring-0 transition duration-150 ease-in-out">View some articles</button></ Link >

        
      </div>
    );
  }
}

export default Confirm;