import React from 'react'
import { Link } from "react-router-dom";

function Menu(props) {

  return (
    <ul class="list-none text-white flex flex-col md:flex-row m-0  md:font-medium">
      <li>
        <Link to="/Cases/1" className="text-white" onClick={props.closeMenu}>
          Case Studies
        </Link>
      </li>
      <li>
        <Link to="/about" className="text-white" onClick={props.closeMenu}>
          About
        </Link>
      </li>
      <li>
        <Link to="/posts/1/1" className="text-white" onClick={props.closeMenu}>
          Posts
        </Link>
      </li>

      <Link to="/Contact" className="" onClick={props.closeMenu}>
        <button
          type="button"
          className="mt-2 inline-block px-6 py-2 border-2 border-primary bg-primary text-dark font-medium text-xs leading-tight uppercase rounded-full hover:border-secondary hover:bg-secondary hover:text-white focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
        >
          Get Started
        </button>
      </Link>
    </ul>
  );

}


export default Menu;