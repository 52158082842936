import React from "react";
import { default as cross } from "./images/assets/cross.svg";
import { default as squiggle } from "./images/assets/sqiggle.svg";
import "./css/assets.css";
import { useParallax } from "react-scroll-parallax";
import { ScrollEffect } from "../Hooks/ScrollEffect";
import AnimatedAsset from "../Hooks/AnimatedAsset";
//import { ParallaxProvider } from 'react-scroll-parallax';

function TopBanner(props) {
  const crossStyle = {
    position: "absolute",
    width: "10%",
    maxWidth: "120px",
    top: "20px",
    right: "-20px",
  };

  const squiggleImage = {
    left: "-50px",
    bottom: "-100px",
    position: "absolute",
    width: "40%",
    maxWidth: "220px",
  };

  const textSlow = useParallax({
    speed: -15,
  });

  return (
    <div className="relative bg-dark pt-24 pb-24 md:pb-8">
      <div className="opacity-5 md:opacity-100 absolute right-0 bottom-0 md:w-1/3 lg:w-6/12 w-6/12 h-80"></div>

      <ScrollEffect rotateFrom={-10} rotateTo={0} XFrom={0} XTo={0}>
        <div style={crossStyle} className="absolute z-10">
          <AnimatedAsset
            duration={53000}
            rotate={15}
            minusrotate={-300}
            posx={-16}
            minusx={22}
            posy={30}
            minusy={-40}
          >
            <img className="" alt="Cartoon Coin mascot" src={cross} />
          </AnimatedAsset>
        </div>
      </ScrollEffect>
      <div className="relative container px-4 flex flex-wrap md:flex-row-reverse">
        <div className="w-full  mr-auto py-24 md:py-40 text-center md:text-left relative">
          <div>
            <ScrollEffect speed={-8}>
              <h1
                ref={textSlow.ref}
                className="uppercase text-white text-3xl text-center md:text-4xl lg:text-5xl xl:text-6xl leading-tight font-heading relative"
              >
                <span>{props.header}</span>
              </h1>
            </ScrollEffect>
            <ScrollEffect XFrom={100} XTo={-100} speed={-8}>
              <div style={squiggleImage} className="absolute z-10">
                <img className="" alt="sqiggle" src={squiggle} />
              </div>
            </ScrollEffect>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBanner;
