import React from 'react'
import Menu from "./Menu"

function DesktopNav(props){
  return (

      <div class="hidden w-full md:block md:w-auto" id="desktop-menu">
          <Menu />
    </div>
)

}


export default DesktopNav;