import React from "react";
import { useParallax } from "react-scroll-parallax";
import { default as cross } from '../../images/assets/cross.svg'
import { default as squiggle } from "../../images/assets/sqiggle.svg";
import { default as triangle } from "../../images/assets/triangle.svg";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";

import { default as footballImage } from "../../images/assets/football-training.jpg";

export default function ServicesHome() {
  const triangleImage = {
    left: "-2%",
    top: "0px",
    width: "40%",
    maxWidth: "150px",
    position: "absolute",
  };

  const corossImage = {
    right: "-30px",
    bottom: "-30px",
    width: "40%",
    maxWidth: "150px",
    position: "absolute",
  };

  return (
    <div className="bg-light text-dark">
      <ScrollEffect
        rotateFrom={-10}
        rotateTo={0}
        XFrom={0}
        XTo={0}
        YFrom={500}
        YTo={-0}
      >
        <div style={triangleImage} className="absolute z-10 md:block">
          <AnimatedAsset
            duration={53000}
            rotate={15}
            minusrotate={-300}
            posx={-16}
            minusx={22}
            posy={30}
            minusy={-40}
          >
            <img className="" alt="Cartoon Coin mascot" src={triangle} />
          </AnimatedAsset>
        </div>
      </ScrollEffect>
      <section className="container mx-auto pt-16 px-6 py-10">
        <div className="bg-light text-dark">
          <section className="container mx-auto py-12 pt-24 md:py-44 lg:py-22">
            <div class="flex items-center flex-wrap">
              <div class="w-full lg:w-1/2 px-4 mb-22">
                <h4 class="text-3xl md:text-5xl mb-3 uppercase italic leading-none font-heading">
                  Helping Sports in{" "}
                  <span className="text-darklt">Liverpool</span>
                </h4>
                <p class="mb-8">
                  Youth sports play a vital role in the culture of Liverpool, as
                  it not only promotes physical activity but also helps in the
                  overall development of young people. Sports have been a
                  fundamental part of Liverpool's community for generations, and
                  youth sports provide an excellent opportunity for young people
                  to engage in healthy competition, learn valuable life skills,
                  and develop friendships that last a lifetime.
                </p>
              </div>
              <div class="w-full bg-white lg:w-1/2 shadow-xl  rounded-xl relative">
                <div style={corossImage} className="absolute z-10 md:block">
                  <AnimatedAsset
                    duration={53000}
                    rotate={15}
                    minusrotate={-300}
                    posx={-16}
                    minusx={22}
                    posy={30}
                    minusy={-40}
                  >
                    <img className="" alt="Cartoon Coin mascot" src={cross} />
                  </AnimatedAsset>
                </div>

                <img src={footballImage} alt="responsive"></img>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}
