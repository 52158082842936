import React from 'react'
import Menu from "./Menu"
import { default as closeCross } from '../images/menu/cross.svg'
import '../css/menu.css'

function NavigationMenu(props){
  return (

    <div>
      <nav className="p-5">
      <button className="absolute top-5 right-5" onClick={props.closeMenu} ><img width="20px" src={closeCross} /></button>
 
        <div className="mobile-nav align-middle h-full text-lg font-bold pt-16">
        <Menu closeMenu={props.closeMenu} />
        </div>
    </nav>
    </div>
)

}


export default NavigationMenu;
